angular.module('managerApp').directive('autocomplete', function($http, $document) {
    return {
        templateUrl: 'components/autocomplete/autocomplete.html',
        restrict: 'E',
        scope: {model: '=ngModel'},
        controller: function($scope, $element) {
            $scope.controlAutocomplete = '';
            $scope.options = [];
            $scope.showList = false;
            
            // Function to fetch options from the backend
            function fetchOptions(query) {
                return $http.get(`${config.apiURL}autocomplete/`, { params: { query: query } }).then(function(response) {
                    return response.data;
                });
            }

            // Watch for changes in the input field
            $scope.$watch('controlAutocomplete', function(newValue) {
                if (newValue) {
                    // Send request only if the input is not empty
                    fetchOptions(newValue).then(function(data) {
                        $scope.options = data;
                        $scope.filteredOptions = $scope.options;
                        $scope.showList = true;
                    });
                } else {
                    // Clear options if the input is empty
                    $scope.options = [];
                    $scope.filteredOptions = [];
                    $scope.showList = false;
                }
            });
             // Close the list when clicking outside of it
            $document.on('click', function(event) {
                if (!$element[0].contains(event.target)) {
                    $scope.$apply(function() {
                        $scope.showList = false;
                    });
                }
            });
            $scope.selected = function(option){
                $scope.showList = false;
                $scope.controlAutocomplete = option.name;
                delete $scope.$parent.search.filters.partner
                delete $scope.$parent.search.filters.tablet
                delete $scope.$parent.search.filters.terminal
                delete $scope.$parent.search.filters.webApp
                $scope.$parent.search.filters[option.type]=option.id
            }
            
        }
    };
});
